/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Button, UncontrolledTooltip } from "reactstrap";
// core components

function PortfolioHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

// orange, blue, purple, primary
  return (
    <>
      <div className="page-header clear-filter page-header-small" filter-color="orange">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/headers/portfolio.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand" style={{paddingTop:100}}>
            <h1 className="title" style={{color: 'black'}}>My Portfolio</h1>
            <h4 style={{color: 'black'}}>Here are some selected works I've been involved with.</h4>
            <h5 style={{color: 'black'}}><b>Click on the cards to learn more.</b></h5>
          </div>
        </Container>
      </div>
    </>
  );
}

export default PortfolioHeader;
