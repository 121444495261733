import React from "react";
import { useState } from 'react';
//import { Link, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Data from './portfolioDetails.js';

// reactstrap components
import {
  Card,
  CardImg,
  CardTitle,
  CardBody,
  CardText,
  Container,
  Row,
  Col
} from "reactstrap";

var setShowGlobal;
var showGlobal;

//handle
function displayData(){
  var c = 0;
  var items = []
  while(c<=Data.length){
    items.push(singleRow(Data, c));
    c+=2;
  }
  return items;
}

//singleRow deals with 2 entries
function singleRow(data, nums){
  var a = data[nums];
  var b = data[nums+1];
  return (
    <React.Fragment>
      <Row>
        <Col className="ml-auto mr-auto" md="10" xl="6">
          {singleCard(a, nums)}
        </Col>
        <Col className="ml-auto mr-auto" md="10" xl="6">
          {singleCard(b, nums+1)}
        </Col>
      </Row>
    </React.Fragment>
  );
}

//data passed is a single card entry
//TODO: use tags for tech
function singleCard(data, num){
  //if empty, return 0
  if(data==null){
    return;
  }

  const img = require("assets/img/PortfolioTabs/" + data.image);
  var str = "";
  if(data.text.length > 100){
    str = data.text.substring(0, 100) + "...";
  } else {
    str = data.text;
  }

  if(showGlobal[num]){
    str = data.text;
  }

  var c = 0;
  var links = []
  
  for (var key of Object.keys(data.links)) {
    links.push(linkMake(key, data.links[key]));
  }

  while(c<data.links.length){
    links.push(linkMake(data.links[c]));
    c+=1;
  }


  return(
    <React.Fragment >
      <a style={{ cursor: 'pointer' }} onClick={()=>{const newshow = {...showGlobal}; newshow[num]=!showGlobal[num]; setShowGlobal(newshow); console.log(showGlobal);}}>
      <Card style={{boxShadow: "10px 10px 10px #9E9E9E"}} >
        <CardImg alt="..." src={img} top height='250px' 
        style={{objectFit: 'cover', borderWidth: '1px', borderColor: '#9E9E9E', borderBottomStyle: 'solid'}}>
        </CardImg>
        <CardBody style={{color: 'black'}}>
          <CardTitle tag="h3"><b>{data.title}</b></CardTitle>
          <CardText tag="h5">{str}</CardText>
          <CardText>{links}</CardText>
          <CardText><b>Technologies used : </b>{data.tech}</CardText>
        </CardBody>
      </Card>
      </a>
    </React.Fragment>
    );
}

function linkMake(name, link){
  return (<a href={link}>{name+"\n"}</a>);
}

//main component
function PortfolioTabs() {
  var arr = new Array(Data.length);
  var i;
  for(i=0;i<arr.length;i++){
    arr[i] = false;
  }

  const [show, setShow] = useState(arr);
  showGlobal = show;
  setShowGlobal = setShow;
  return (
    <>
      <div className="section section-tabs">
        <Container>
          {displayData(show)}
        </Container>
      </div>
    </>
  );
}

export default PortfolioTabs;
