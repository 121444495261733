/*eslint-disable*/
import React from "react";
import {Link} from "react-router-dom";

// reactstrap components
import { Container, Button, UncontrolledTooltip } from "reactstrap";
// core components

function WorkHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
// orange, blue, purple, primary
  return (
    <>
      <div className="page-header clear-filter page-header-small" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/headers/work.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand" style={{paddingTop:200}}>
            <h1 className="h2-seo"><b>My skills and activities.</b></h1>

            <Link to="/resume.pdf" target="_blank">
            <Button id="res-download" color="primary" size="lg">
                <b>RESUME</b>
            </Button>
            </Link>
            <UncontrolledTooltip target="#res-download">
              Download my resume.
            </UncontrolledTooltip>
          </div>
        </Container>
      </div>
    </>
  );
}

export default WorkHeader;
