export default [
  {
    id: 1,
    city: 'Singapore',
    color: 'gold',
    coordinates: [1.2902, 103.8519],
    value: 25,
    message: 'This is home! This is where I was born and raised.',
  },
  {
    id: 5,
    city: 'Los Angeles',
    color: 'gold',
    coordinates: [34.052235, -118.243683],
    value: 25,
    message: '',
  },


  
  {
    id: 3,  
    city: 'Cusco',
    color: 'yellow',
    coordinates: [-13.525, -71.97222],
    value: 15,
    message: '',
  },
  {
    id: 3,
    city: 'Santiago',
    color: 'yellow',
    coordinates: [-33.4489, -70.6693],
    value: 15,
    message: '',
  },
  {
    id: 3,
    city: 'Buenos Aires',
    color: 'yellow',
    coordinates: [-34.6037, -58.3816],
    value: 15,
    message: '',
  },
  {
    id: 3,
    city: 'Calama',
    color: 'yellow',
    coordinates: [-22.4537, -68.9294],
    value: 15,
    message: '',
  },
  {
    id: 3,
    city: 'Iguazu',
    color: 'yellow',
    coordinates: [-25.6953, -54.4367],
    value: 15,
    message: '',
  },
  


  {
    id: 4,
    city: 'San Francisco',
    color: 'yellow',
    coordinates: [37.773972, -122.431297],
    value: 15,
    message: '',
  },
  {
    id: 7,
    city: 'Vancouver',
    color: 'yellow',
    coordinates: [49.2827, -123.1207],
    value: 15,
    message: '',
  },
  {
    id: 7,
    city: 'Banff',
    color: 'yellow',
    coordinates: [51.1784, -115.5708],
    value: 15,
    message: '',
  },
   {
    id: 7,
    city: 'Jasper',
    color: 'yellow',
    coordinates: [52.8737, -118.0814],
    value: 15,
    message: '',
  },
  {
    id: 7,
    city: 'Alaska',
    color: 'yellow',
    coordinates: [64.2008, -149.4937],
    value: 15,
    message: '',
  },






  {
    id: 6,
    city: 'Nepal',
    color: 'yellow',
    coordinates: [28.3948574, 84.1240082],
    value: 15,
    message: '',
  },
  {
    id: 8,
    city: 'Bali',
    color: 'yellow',
    coordinates: [-8.409518,115.188919],
    value: 15,
    message: '',
  },
  {
    id: 9,
    city: 'Medan',
    color: 'yellow',
    coordinates: [3.5833299,98.6666718],
    value: 15,
    message: '',
  },
  {
    id: 10,
    city: 'Vietnam',
    color: 'yellow',
    coordinates: [21.028511,105.804817],
    value: 15,
    message: '',
  },
  {
    id: 11,
    city: 'Tokyo',
    color: 'yellow',
    coordinates: [35.652832,139.839478],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Hokkaido',
    color: 'yellow',
    coordinates: [43.066666,141.350006],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'South Korea',
    color: 'yellow',
    coordinates: [37.517235,127.047325],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Brunei',
    color: 'yellow',
    coordinates: [4.5352769,114.7276688],
    value: 15,
    message: '',
  },
  {
    id: 11,
    city: 'Cambodia',
    color: 'yellow',
    coordinates: [12.5656786,104.9909668],
    value: 15,
    message: '',
  },
  {
    id: 11,
    city: 'Taiwan',
    color: 'yellow',
    coordinates: [25.105497,121.597366],
    value: 15,
    message: '',
  },
  {
    id: 11,
    city: 'Hainan',
    color: 'yellow',
    coordinates: [20.0458298,110.3416672],
    value: 15,
    message: '',
  },
  {
    id: 2,
    city: 'Xi\'An',
    color: 'yellow',
    coordinates: [34.341568, 108.940178],
    value: 15,
    message: '',
  },
  {
    id: 11,
    city: 'Israel',
    color: 'yellow',
    coordinates: [31.046051,34.8516121],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Manila',
    color: 'yellow',
    coordinates: [14.599512,120.984222],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Perth',
    color: 'yellow',
    coordinates: [-31.95224,115.8614],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'New Zealand',
    color: 'yellow',
    coordinates: [-36.848461,174.763336],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Hong Kong',
    color: 'yellow',
    coordinates: [22.3193,114.1694],
    value: 15,
    message: '',
  },





  {
    id: 11,
    city: 'Vienna',
    color: 'yellow',
    coordinates: [48.2082,16.3738],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Krakow',
    color: 'yellow',
    coordinates: [50.0647,19.9450],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Sarajevo',
    color: 'yellow',
    coordinates: [43.8563,18.4131],
    value: 15,
    message: '',
  },  
  {
    id: 11,
    city: 'Venice',
    color: 'yellow',
    coordinates: [45.4408,12.3155],
    value: 15,
    message: '',
  },      
  {
    id: 11,
    city: 'Ljubljana',
    color: 'yellow',
    coordinates: [46.0569,14.5058],
    value: 15,
    message: '',
  },      
  {
    id: 11,
    city: 'Berlin',
    color: 'yellow',
    coordinates: [52.5200,13.4050],
    value: 15,
    message: '',
  },
  {
    id: 11,
    city: 'Prague',
    color: 'yellow',
    coordinates: [50.0755,14.4378],
    value: 15,
    message: '',
  },
  {
    id: 11,
    city: 'Budapest',
    color: 'yellow',
    coordinates: [47.4979,19.0402],
    value: 15,
    message: '',
  },
/*
  { //declare a plane ID
    id: 100,
    ends: 'LAX-SIN',
    color: 'yellow',
    coordinates: [34.052235, -118.243683],
    value: 20,
    message: '',

    from: [34.052235, -118.243683],
    to: [1.2902, 103.8519]
    //to: [37.773972, -122.431297]
  },
  */          
];
