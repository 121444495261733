import React, { useState } from "react";

import SiteNavbar from "./Shared/SiteNavBar.js";
import { Container, Row, Col} from "reactstrap";
import {Redirect} from "react-router-dom";

import { getAllCities } from './ExternalHandler';
import PhotoGallery from './Travels/PhotoGallery';

import {TabContent, TabPane} from 'reactstrap';

// hosted at /travels/:country. 
function GalleryPage(props) {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  
  //Page details
  let country = props.match.params.place;
  let photos = getAllCities(country);

  if(photos==null){
    return(<Redirect to="/travels" />);
  }

  let galleries = [];
  Object.keys(photos).forEach(key => {
    galleries.push(
      <Container className="text-left">
        <h2 className="title">{capName(key)}</h2>
        <PhotoGallery photos={photos[key]}/>
      </Container>
    )
  })

  let randomCity = getRandomProperty(photos)
  let headerImage = photos[randomCity][0].src;
  
  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <div className="page-header page-header-small" >
          <div 
            className="page-header-image"
            style={{
              backgroundImage: "url(" + headerImage + ")",
              filter:'blur(5px) sepia(50%)'
            }}
            ref={pageHeader}
          ></div>
          <Container>
            <div className="content-center brand" style={{paddingTop: 100, fontSize:'2vw'}}>
              <h1 className="title">{capName(country)}</h1>
            </div>
          </Container>
        </div>
        <div className="main">
          <div className="section">

            <TabbedItems photos={photos} />
            <WhiteSpace />
            <WhiteSpace />
          </div>
        </div>
      </div>
    </>
  );
}
export default GalleryPage;

//used to get a random city given a country directory.
function getRandomProperty(obj) {
    var keys = Object.keys(obj);
    return keys[ keys.length * Math.random() << 0];
};

//capitalizes city and country name
function capName(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


//Tabs of different photo galleries for different cities in a country.
function TabbedItems(props){
  let photos = props.photos;
  let starterCity = capName(getRandomProperty(photos));
  const [city, setCity] = useState(starterCity);
  let numCities = Object.keys(photos).length;

  let w = Math.floor(12/numCities);
  w = Math.max(2,w);

  let options = []
  
  let selected={
    cursor: 'auto'
  }
  let deSelected={
    cursor: 'pointer',
    fontWeight: 'normal',
    color: 'blue',
    textDecoration: 'underline'
  }


  Object.keys(photos).forEach(key => {
    let name = capName(key);
    options.push(
      <Col lg={w.toString()} md={w.toString()} key={key}>
        <h1 style={city==name ? selected : deSelected} className="title" onClick={() => {setCity(name)}}>{name}</h1>
      </Col>
    )
  })

  let results = []
  Object.keys(photos).forEach(key => {
    let name = capName(key);
    results.push(
      <TabPane tabId={name} key={key}>
        <Container>
          <PhotoGallery photos={photos[key]} key={key} />
        </Container>
      </TabPane> 
    )
  })
  return(
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            {options}
          </Row>
          <div className="space-50"></div>
        </Container>
        <TabContent activeTab={city}>
          {results}
        </TabContent>
      </div>
    </>
  )
}


function WhiteSpace(){
  return(
    <>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
      <div className="space-50"></div>
    </>
  )
}