import React from "react";

import SiteNavbar from "./Shared/SiteNavBar.js";
import GlobeItem from './Travels/GlobeItem.js';

function GlobePage() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <div className="main">
        <GlobeItem />
        </div>
      </div>
    </>
  );
}

export default GlobePage;


