import React from "react";
// reactstrap components

import { Button, Container, Row, Col } from "reactstrap";

//generate table to display reminder information
function createTable(info){
  let table = []
  table.push(
    <div className="d-none d-md-block">
          <Row className="justify-content-md-center">
            <Col lg="3" md="3">
              <h3 style={{textAlign : 'left'}}><b>Time</b></h3>
            </Col>
            <Col lg="7" md="7">
              <h3 style={{textAlign : 'left'}}><b>To-do</b></h3>
            </Col>
            <Col lg="2" md="2">
              <h3 style={{textAlign : 'left'}}><b>Who</b></h3>
            </Col>
          </Row></div>);
  //table.push(<hr className="d-block d-md-none" style={{color: "white", backgroundColor: "white", height: 5}} />);
  
  var i;
  let semiTable = [];
  for(i=0;i<info.length;i++){
    //for each reminder, parse data
    let name = info[i].name;
    let action = info[i].action;
    let time = info[i].time;
    let reqtime = info[i].posted;

    //treat results
    action = action.replace(/^\w/, c => c.toUpperCase());
    time = new Date(parseInt(time)*1000);
    time = time.toDateString().substring(0,3) + ", " + time.toTimeString().substring(0,8); //3,10 gives date
      semiTable.push(
          <Row className="justify-content-md-center">
            <Col lg="3" md="3">
              <h4 style={{textAlign : 'left'}}>{time}</h4>
            </Col>
            <Col lg="7" md="7">
              <h4 style={{textAlign : 'left'}}>{action}</h4>
            </Col>
            <Col lg="2" md="2">
              <h4 style={{textAlign : 'left'}}>{name}</h4>
            </Col>
          </Row>);
      semiTable.push(<hr className="d-block d-md-none" style={{color: "white", backgroundColor: "white", height: 5}} />);
  }
  table.push(<Container style={{overflow:"auto", height: '80%'}}>{semiTable}</Container>);
  return table;
}

function ChamberScreen(props) {
  let table = createTable(props.data.remind);

  let nameStr = props.data.name;
  nameStr = nameStr.replace(/^\w/, c => c.toUpperCase());
    
  let str = "Your group has a total of " + props.data.remind.length + " reminders.";
  
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
//226784322
  return (
    <>
    <Container style={{ top : 0, height: '80%', textAlign: 'left'}}>
      <h2>Welcome, {nameStr}</h2>
        <h4 style={{color: 'snow'}}>{str}</h4>
          {table}
        <Col className="ml-auto mr-auto" md="4" style={{bottom : 0, paddingTop:50}}>
          <Button
            block
            className="btn-round"
            color="neutral"
            onClick={(e) => props.setLogin({login : 0})}
            size="lg"
          ><b>Logout</b>
          </Button>
        </Col>
      </Container>
    </>
  );
}

export default ChamberScreen;
