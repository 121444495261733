import React, { useState } from "react";

import SiteNavbar from "./Shared/SiteNavBar.js";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Redirect } from "react-router-dom";

import './Travels/frames.css';
import { getCountries } from './ExternalHandler';


//page hosted at /travels 
function TravelPage(props) {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  
  let headerPage = require("assets/img/travels/header.jpg");

  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <div className="page-header page-header-small" >
          <div 
            className="page-header-image"
            style={{
              backgroundImage: "url(" + headerPage + ")",
              filter:'blur(2px) sepia(50%)'
            }}
            ref={pageHeader}
          ></div>
          <Container>
            <div className="content-center brand" style={{paddingTop: 100, fontSize:'2vw'}}>
              <h2 className="title">Photo Galleries</h2>
              <Button color="info" size="md" href="/travels/globe" id="intro-tooltip">
                See the world.
                <UncontrolledTooltip target="#intro-tooltip">
                  It's a really cool widget.
                </UncontrolledTooltip>
              </Button>
            </div>
          </Container>
        </div>
        <div className="main">
          <div className="section">
            <Container className="text-center">
              <Row className="justify-content-md-center">
                <FrameSet />
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
export default TravelPage;

//Set of frames displayed in a grid form. Gets list of photos directly from ExternalHandler
function FrameSet(){
  let list =[];
  let countries = getCountries();

  Object.keys(countries).forEach(key => {
    list.push(
      <Col lg="6" md="6">
        <Frame src={countries[key]} country={key} />
        <h2>{capName(key)}</h2>
      </Col>
    )
  })

  return list;
}

//Each individual frame. Contains styles.
function Frame(props){
  const [redirect, setRedirect] = useState(false);
  const divStyle = {
    width : '100%',
    
    paddingTop : 16,
    paddingBottom : 16,
    paddingLeft: 0,
    paddingRight: 0
  }

  if(redirect){
    return(<Redirect push to={"/travels/" + props.country} />)
  }
//style ={imgStyle}
  return (
    <div style={divStyle}>
      <img
        alt="..."
        className="rounded"
        src={props.src}
        
        onClick={()=>{setRedirect(true)}}
      ></img>
    </div>
  )
}
//Helper function to capitalize names.
function capName(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
