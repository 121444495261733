import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function HomeIntro() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">A bit about myself</h2>
              <h5>
                I'm a Computer Science Undergraduate student at UCLA ('22). 
                I was born and raised in Singapore; I completed high school, and completed National Service there before moving to Los Angeles.
              </h5>
              <h5>
                  I love traveling, hiking and mountaineering. (Read about my travels if you're interested!)
                  I do Brazilian Jiu-jitsu, and I enjoy karaoke, boba, and playing the piano. 
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HomeIntro;
