/*eslint-disable*/
import React, {useState} from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

import ChamberLogin from "./ChamberLogin.js";
import ChamberScreen from "./ChamberScreen.js";

function ChamberHeader() {
  //stores login data 
  const [data, setData] = useState({ login : 0 }) // 0 not attempted, 1 attempted, 2 success

  //give setData as callback function.
  var disp = []
  if(data.login<2){
    disp.push(<ChamberLogin setLogin={setData} login={data} />); //throws warning. TODO
  } else {
    disp.push(<ChamberScreen data={data} setLogin={setData} />);
  }

  return (
    <>
      <div className="page-header clear-filter" filter-color="orange">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/headers/chamber.jpg") + ")"
          }}
        ></div>
          {disp}
        </div>
    </>
  );
}

export default ChamberHeader;
