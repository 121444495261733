import React from "react";

import SiteNavbar from "./Shared/SiteNavBar.js";
import PortfolioTabs from "./Portfolio/PortfolioTabs.js";
import PortfolioHeader from "./Portfolio/PortfolioHeader.js";

function PortfolioPage() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <PortfolioHeader />
        <div className="main">
          <PortfolioTabs />
        </div>
      </div>
    </>
  );
}

export default PortfolioPage;