import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col
} from "reactstrap";


//const proxyurl = "https://cors-anywhere.herokuapp.com/";
//var backURL = 'http://localhost:8000/chambersBot/connect/'; //URL used to test locally
var backURL = 'https://afternoon-dusk-42551.herokuapp.com/chambersBot/connect/'; //correct production URL
function ChamberLogin(props) {
  const [attempted, setAttempted] = useState(false); // 0 not attempted, 1 attempted, 2 success

  var warn = [];
  if(attempted){
    warn.push(<h6>Incorrect passcode.</h6>);
  };

  function loginAttempt(event, fname, pcode){
    event.preventDefault(); 

    if(fname=="" || pcode=="") //check if empty
      return;
    
    //do POST, attempt login
    var obj = { code: pcode, action:'login' };
    fetch(backURL, { method: 'POST', body: JSON.stringify(obj)})
    .then(response => response.json())
    .then(response=> {
      console.log(response);
      //Successful login
      let data;
      if(response['login']==2){
        data = {login: 2, name: fname, code : pcode, remind: response['remind']};
        props.setLogin(data);
      } 
      //Failure
      else {
        data = {login: 1};
        props.setLogin(data);
        setAttempted(true);
      }
    });
  }


  const [name, setName] = React.useState("");
  const [code, setCode] = React.useState("");

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
    <Container>
        <div className="content">
          <h1>Chambers</h1>
          <h3>Welcome.</h3>
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <div className="logo-container">
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_single-02"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        type="text"
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={(e)=> setName(e.target.value)}
                        maxLength="20"
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons objects_key-25"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Passcode"
                        type="text"
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        onChange={(e)=> setCode(e.target.value)}
                        onKeyDown={(e)=> { if(e.keyCode==13) loginAttempt(e,name,code) }}
                        maxLength="20"
                      ></Input>
                    </InputGroup>
                  </CardBody>

                    
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="neutral"
                      onClick={(e) => loginAttempt(e,name,code)}
                      size="lg"
                    ><b>Enter</b>
                    </Button>
                  </CardFooter>

                  <div style={{color:'pink'}}>{warn}</div>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default ChamberLogin;
