/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages for this kit

import HomePage from "pages/HomePage.js";
import GlobePage from "pages/GlobePage.js";
import WorkPage from "pages/WorkPage.js";
import PortfolioPage from "pages/PortfolioPage.js";

import TravelsPage from "pages/TravelPage.js";
import GalleryPage from "pages/GalleryPage.js";

//test new import
import ChamberPage from "pages/ChamberPage.js";

const reload = () => window.location.reload();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/home" render={props => <HomePage {...props} />} />
      <Route path="/travels" render={props => <TravelsSwitch {...props} />} />
      <Route path="/work" render={props => <WorkPage {...props} />} />
      <Route path="/portfolio" render={props => <PortfolioPage {...props} />} />
      <Route path="/chambers" render={props => <ChamberPage {...props} />} />

      <Redirect from="/" to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);



function TravelsSwitch(){
  return(
    <>
      <Switch>
        <Route path="/travels/globe" render={props => <GlobePage {...props} />} />
        <Route path="/travels/:place" render={props => <GalleryPage {...props} />} />
        <Route path="/travels" render={props => <TravelsPage {...props} />} />
      </Switch>
    </>
  )
}