import React from "react";

import SiteNavbar from "./Shared/SiteNavBar.js";
import WorkHeader from "./Work/WorkHeader.js";
import AltResume from "./Work/AltResume.js"

function WorkPage() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <WorkHeader />
        <div className="main">
          <AltResume />
        </div>
      </div>
    </>
  );
}

export default WorkPage;