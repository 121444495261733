import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function listOut(libs){
  return (
    <div style={{textAlign : 'left'}}>
      {libs.map(item => (
        <h5><li>{item}</li></h5>
      ))}
    </div>
  )
}

function listActs(prop){
  const imgStyle = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  }

  return (
    <div style={{textAlign : 'left'}}>
      {prop.map(item => (
        <Row>
          <Col lg="3" md="3">
            <div id="images">
              <img
                  alt="..."
                  src={require("assets/img/Work/" + item.image)}
                  style={imgStyle}
              ></img>
            </div>
          </Col>
          <Col lg="9" md="9">
            <div style={{textAlign : 'left'}}>
              <h5 className="title">{item.title}</h5>
              {item.desc.map(desc => (<h5>{desc}</h5>))}
            </div>
          </Col>
        </Row>
        
      ))}
    </div>
  )
}

//Describes my profiencies and experiences.
function AltResume() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <h1 className="title">Technologies</h1>
          <hl></hl>
          <Row>
            <Col lg="4" md="4">
              <h3 className="title" style={{textAlign : 'left'}}>Frontend</h3>
              {listOut(DATA.front)}
            </Col>
            <Col lg="4" md="4">
              <h3 className="title" style={{textAlign : 'left'}}>Backend</h3>
              {listOut(DATA.back)}
            </Col>
            <Col lg="4" md="4">
              <h3 className="title" style={{textAlign : 'left'}}>Database</h3>
              {listOut(DATA.db)}
            </Col>
          </Row>

          <Row>
            <Col lg="4" md="4">
              <h3 className="title" style={{textAlign : 'left'}}>Languages</h3>
              {listOut(DATA.languages)}
            </Col>
            <Col lg="4" md="4">
              <h3 className="title" style={{textAlign : 'left'}}>Tools</h3>
              {listOut(DATA.tools)}
            </Col>
          </Row>


          <h1 className="title">Activities</h1>
          {listActs(acts)}
          
        </Container>
      </div>
    </>
  );
}

export default AltResume;

const DATA = {
  front : [
    "HTML5",
    "CSS3",
    "React.js",
    "Angular"
  ],
  back : [
    "Spring Boot",
    "Node.js",
    "Django",
    "Express.js"
  ],
  db : [
    "MySQL",
    "MongoDB"
  ],
  languages: [
    "Python",
    "Java",
    "Javascript",
    "C",
    "C++",
    "Bash"
  ],
  tools: [
    "Git",
    "Docker",
    "Android Studio"
  ]
}
const acts = [
  {
    title: "Jane Street Electronic Trading Challenge LA, 2019",
    image: "etc.png",
    desc: [
    "I participated in the Jane Street ETC with two schoolmates; we were tasked with creating trading bots to compete against other teams in a simulated market.",
    "The experience was amazingly fun and exciting. By employing techniques such as penny-pinching, ETF arbitrage and ADR arbitrage, we managed to clinch fourth place in the main round of the competition!"
    ]
  },

  {
    title: "JPMorgan & Chase Virtual Internship",
    image: "jpmchase_vi.jpg",
    desc: [
    "I completed the JPMorgan & Chase virtual internship in November 2019. This exposed me to the work of software engineers, in the format of resolving tickets, fixing bugs and providing new capabilities.",
    "I was able to use my knowledge of frontend and backend frameworks to resolve the issues in the trading platforms. The overall experience I had was greatly enjoyable and reminded me of my passion for software."
    ]
  },

  {
    title: "Daily Bruin Online: Internal Tools",
    image: "daily_bruin.png",
    desc: [
      "I joined Daily Bruin's internal tools team in November 2019. After completing my initial DBO training, which covered all sorts of technologies I would require for software engineering in this club, I was staffed on Kerckhoff, a content management and deployment system in the works. I'm currently familiarizing myself with the code base, working on increasingly significant tasks to build up the system."
    ]
  },
]