/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Button, UncontrolledTooltip } from "reactstrap";
// core components

function HomeHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

// orange, blue, purple, primary
  return (
    <>
      <div className="page-header clear-filter page-header-small" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/headers/home.JPG") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand" style={{paddingTop:100}}>
            <h1 className="title" >Hi, I'm Wen Hong.</h1>
            <h3>Aspiring software engineer</h3>
            <h4>Seeking a Software Engineering Internship for Summer 2021.</h4>
            <Button color="default" size="lg" href="/work" id="intro-tooltip">
                See what I've got to offer
                 <UncontrolledTooltip target="#intro-tooltip">
                  See my skills and proficiencies.
                </UncontrolledTooltip>
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default HomeHeader;
