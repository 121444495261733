import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

//import { Carousel, Modal } from "reactstrap";


//Photo gallery with Light box.
function PhotoGallery(props) {
  let photos = props.photos;

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const customStyles = {
    view: () => ({
      // none of react-images styles are passed to <View />
      lineHeight: 0,
      position: 'relative',
      textAlign: 'center',
    })
  }

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} direction={randomDirection()} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              styles={customStyles}
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}
export default PhotoGallery;


//Randomly returns either row or column
function randomDirection(){
  let choices = ["row","column"];
  let choice = Math.round(Math.random());
  return choices[choice];
}