import React, { useState } from 'react';

import defaultMarkers from './GlobeMarkers.js';
import markerRenderer from './markerRenderer.js';

import ReactGlobe from 'react-globe';

//Tooltip upon hover of the globe
function getTooltipContent(marker) {
  //return `CITY: ${marker.city} (Value: ${marker.value})`;
  return `${marker.city}`;
}

function getParaContent(marker){
  return `${marker.message}`;
}


function GlobeItem() {
  //Set up states
  const markers = defaultMarkers;
  const [city, setCity] = useState("Welcome!");
  const [details, setDetails] = useState(
    "On this globe I've marked out most of the places I've been to. \
    Feel free to have a look! Hover over the markers for information. \
    Click on this panel to close it." );

  //onClick action
  function onClickMarker(marker, markerObject, event) {
    if(`${marker.message}`===0)
      return;
    //Set city and paragaraph
    setDetails(getParaContent(marker));
    setCity(getTooltipContent(marker));

  }

  function onDefocus(previousCoordinates, event) {
    //Clear city and paragaraph
    setDetails(null);
    setCity(null);
  }

  function clearPanel(){
    if(city==="Welcome!"){
      setDetails(null);
      setCity(null);
    }
  }

  const globeOptsDay = {
    texture: require("assets/img/earthmap10k.jpg"),
    enableClouds: false,
  };
  const globeOptsNight = {
    texture: 'https://raw.githubusercontent.com/chrisrzhou/react-globe/master/textures/globe_dark.jpg',
    enableClouds: false
  };
  const markerOpts={
    getTooltipContent,
    renderer: markerRenderer,
  }

  const [globeOpts, setGlobe] = useState(globeOptsDay);
  const [isDay, setIsDay] = useState(true);

  function changeScheme(){
    //if is day, change settings and set day to false
    if(isDay==true){
      setGlobe(globeOptsNight);
      setIsDay(false);
    } else {
      setGlobe(globeOptsDay);
      setIsDay(true);
    }
  }

  return (
    <div  style={{ width: '100vw', height: '100vh' }}>
      <ReactGlobe
        globeOptions={globeOpts}
        markers={markers}
        markerOptions={markerOpts}
        onClickMarker={onClickMarker}
        onDefocus={onDefocus}
      />
      {details && (
        <div onClick={clearPanel} style={{
            borderRadius:25,
            background: 'white',
            position: 'absolute',
            top: 100,
            left: 70,
            right: 70,
            paddingBottom:25,
            paddingLeft:30,
            paddingRight:30,
          }}>
          <h5 className="title">{city}</h5>
          <h5>{details}</h5>
        </div>
      )}
      <div style={{
            position: 'absolute',
            borderRadius:25,
            bottom: 15,
            left: 30,
          }}><a style={{ cursor: 'pointer' }} onClick={()=>{changeScheme()}}><h5 className="title" style={{color:'white'}}><u>Toggle Daylight</u></h5></a></div>
    </div>
  );
}
export default GlobeItem;